<template>
  <div class="page-content">
    <!-- <div class="page-content-title">订单审核</div> -->
    <div class="main-content">
      <a-card style="width: 100%" size="small">
        <div class="flex-box">
          <div class="left">
            <a-space size="small">
              {{ orderDetail.studentName }}
              <a-tag color="red">{{ gradeFormat(orderDetail.grade) }}</a-tag>
              <a-tag color="green">{{ studentStatusFormat(orderDetail.studentStatus) }}</a-tag>
            </a-space>
            <div class="address">{{ orderDetail.studentSchoolName }}</div>

          </div>
          <a-button type="link">{{ orderStatusFormat(orderDetail.orderStatus) }}</a-button>
        </div>
      </a-card>

      <a-card style="width: 100%" size="small" title="订单信息" class="mt-10">
        <div style="display: flex;">
          <Cell label="订单类型">
            <a-tag color="green">{{ orderTypeFormat(orderDetail.type) }}</a-tag>
          </Cell>
          <Cell label="经办校区" :value="orderDetail.schoolName" />
        </div>
        <Cell label="附件">
          <a-image-preview-group>
            <a-image :width="50" style="padding-left: 3%;" v-for="annex in orderDetail.annexVoList" :key="annex.uid"
              :src="annex.url" />
          </a-image-preview-group>
        </Cell>
        <Cell label="订单备注" :value="orderDetail.remark" />
        <Cell label="失败原因" :value="orderDetail.failedRemark" v-if="orderDetail.failedRemark" />
      </a-card>

      <a-card style="width: 100%" size="small" title="支付信息" class="mt-10">
        <Cell label="订单原价" :value="'¥' + orderDetail.courseAmount" />
        <div style="display: flex;">
          <Cell label="应收" :value="'¥' + orderDetail.shouldPay" />
          <Cell label="优惠金额" :value="'¥' + orderDetail.preferentialAmount" />
        </div>

        <div style="margin-top: 10px;" v-for="pay in orderDetail.payList" :key="pay.payId">
          <Cell label="支付类型">
            <a-tag color="red">{{ payTypeFormat(pay.payType) }}</a-tag>
          </Cell>
          <Cell label="支付金额" :value="pay.payAmount" />
          <Cell label="支付流水号/单号" :value="pay.serialNumber" />
        </div>
      </a-card>
      <a-card style="width: 100%" size="small" title="课程信息" class="mt-10">
        <div v-for="course in orderDetail.studyCourseList" :key="course.orderCourseId">
          <div class="courseTitle">
            <span style="margin-right: 7px;">{{ course.courseName }}</span>
            <a-tag color="red">{{ gradeFormat(course.grade) }}</a-tag>
            <a-tag color="blue">{{ subjectFormat(course.subject) }}</a-tag>
          </div>
          <div style="font-size: 12px">
            <Cell label="课程原价" :value="'￥' + course.coursePay" />
            <div style="padding: 10px 15px;">￥{{ course.totalPrice }}×{{ course.buyClassHourCount }}（
              <span style="color: red;">赠送{{ course.giftClassHourCount }}</span>
              ）
            </div>
            <!-- <Cell label="其它扣减" :value="'￥' + course.reductionAmount" /> -->
            <Cell label="优惠后金额" :value="'￥' + getPayAmount(course)" />
          </div>
        </div>
      </a-card>
    </div>

    <div class="page-content-footer" v-if="orderDetail.orderStatus == 1 || orderDetail.orderStatus == 7">
      <a-button type="primary" style="width: 45%;height: 50px;" danger @click="cancel">不通过</a-button>
      <a-button type="primary" style="width: 45%;height: 50px;margin-left: 3%;" @click="confirm">通过</a-button>
    </div>

    <van-dialog v-model:show="show" title="备注" show-cancel-button @confirm="auditFailed">
      <van-field v-model="auditDetail.remark" placeholder="" label-align="left" rows="2" autosize type="textarea"
        show-word-limit />
    </van-dialog>


  </div>
</template>

<script>
import Cell from '@/component/cell.vue'
import { getOrder, getWxUserIdByCode, audit } from '@/api/order'
// import { getOrder, audit } from '@/api/order'
import { showConfirmDialog } from 'vant';
import { useRoute } from 'vue-router';
import { showSuccessToast } from 'vant';




export default {
  name: 'App',
  components: {
    Cell
  },
  mounted() {
    const route = useRoute();
    getWxUserIdByCode(route.query.code).then(response => {
      this.auditDetail.wxUserId = response.userId
      getOrder(route.query.state, this.auditDetail.wxUserId).then(response => {
        this.auditDetail.orderId = response.orderId
        this.orderDetail = response
      })
      this.getDicts('grade_list').then(response => {
        this.gradeList = response
      })
      this.getDicts('student_status').then(response => {
        this.studentStatusList = response
      })
      this.getDicts('order_type').then(response => {
        this.orderTypeList = response
      })
      this.getDicts('order_status').then(response => {
        this.orderStatusList = response
      })
      this.getDicts('pay_type').then(response => {
        this.payTypeList = response
      })
      this.getDicts('course_subject').then(response => {
        this.subjectList = response
      })
    })
  },
  data() {
    return {
      orderDetail: {
      },
      wxUserId: undefined,
      gradeList: [],
      studentStatusList: [],
      orderTypeList: [],
      orderStatusList: [],
      payTypeList: [],
      subjectList: [],
      show: false,
      value: undefined,
      auditDetail: {
        orderId: undefined,
        auditResult: undefined,
        remark: ''
      }
    }
  },
  methods: {
    gradeFormat(grade) {
      return this.selectDictLabel(this.gradeList, grade)
    },
    studentStatusFormat(studentStatus) {
      return this.selectDictLabel(this.studentStatusList, studentStatus)
    },
    orderTypeFormat(orderType) {
      return this.selectDictLabel(this.orderTypeList, orderType)
    },
    orderStatusFormat(orderStatus) {
      return this.selectDictLabel(this.orderStatusList, orderStatus)
    },
    payTypeFormat(payType) {
      return this.selectDictLabel(this.payTypeList, payType)
    },
    subjectFormat(subject) {
      return this.selectDictLabel(this.subjectList, subject)
    },
    getPayAmount(course) {
      let payAmount = course.coursePay
      if (this.orderDetail.discountRate && this.orderDetail.discountRate != 0) {
        payAmount = payAmount * (this.orderDetail.discountRate / 10)
      }
      if (course.reductionAmount) {
        payAmount = payAmount - Number(course.reductionAmount)
      }
      return payAmount
    },
    initOrder(orderId) {
      getOrder(orderId).then(response => {
        this.auditDetail.orderId = response.orderId
        this.orderDetail = response
      })
    },
    auditFailed() {
      this.auditDetail.auditResult = 0
      audit(this.auditDetail).then(() => {
        showSuccessToast("操作成功")
        this.initOrder(this.auditDetail.orderId)
      });
    },
    confirm() {
      showConfirmDialog({
        title: '',
        message:
          '确认该订单审核通过？',
      }).then(() => {
        this.auditDetail.remark = ''
        this.auditDetail.auditResult = 1
        audit(this.auditDetail).then(() => {
          showSuccessToast("操作成功")
          this.initOrder(this.auditDetail.orderId)
        });
      }).catch(() => {

      })
    },
    cancel() {
      this.show = true
    }
  }
}


</script>

<style>
.page-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f0f2f5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  overflow: auto;
  padding: 10px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    flex: 1;
  }
}

.page-content-title {
  text-align: center;
  padding: 10px;
}

.address {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.courseInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-content-footer {
  text-align: center;
  padding: 10px;
}

.courseTitle {
  color: #1890ff;
  display: flex;
  align-items: center;
}

.courseTitle::before {
  content: '';
  display: inline-block;
  width: 3px;
  height: 15px;
  background: #1890ff;
  margin-right: 5px;
}
</style>
