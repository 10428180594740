import request from '@/utils/request'

// 获取订单信息
export function getOrder(orderId,wxUserId) {
  return request({
    url: '/financial/order/getOrderForWx/' + orderId + '/' + wxUserId,
    method: 'get'
  });
}

// 获取企微用户id
export function getWxUserIdByCode(code) {
  return request({
    url: '/getWxUserIdByCode/' + code,
    method: 'get'
  });
}

// 审核课程
export function audit(params) {
  return request({
    url: '/financial/orderAudit/wxAudit',
    method: 'post',
    params: params
  });
}